import React, {useEffect} from 'react'
import {useReactToPrint} from 'react-to-print'

type ActionButtonProps = {
  componentToPrint: React.MutableRefObject<null>
  id: string
}

export const ActionButton = (props: ActionButtonProps) => {
  const {componentToPrint, id} = props

  const reactToPrintFn = useReactToPrint({
    contentRef: componentToPrint,
    pageStyle: '@page { size: 30mm 20mm }'
  });

  useEffect(() => {
    reactToPrintFn()
  }, [])

  return (
    <div className='visually-hidden'>
      <button onClick={() => reactToPrintFn}>Print</button>
    </div>
  )
}
