import {Formik, Form} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {IEditWebsite, IWebsite} from '../../../core/_models'
import {websitesApi} from '../../../core/_requests'
import AgreementDate from '../../../../settings/editing-page/tabs/overview/AgreementDate'
import {CKEInput} from "../../../../../modules/custom/form-elements/CKEInput";

interface Props {
  website: IWebsite
  invalidateWebsite: () => void
}

export function TermsOfServiceTab({website, invalidateWebsite}: Props) {
  const {locale} = useParams()
  const currLocale = locale || website?.enabledLocales[0] || 'uk'
  const [dateState, setDateState] = useState<any>(new Date(website?.tosUpdatedAt))
  const arrayDescr =
    website?.translations &&
    website?.translations[currLocale] &&
    website?.translations[currLocale].tos
  const initialValues: {tos: string; tosUpdatedAt: Date} = {
    tos: arrayDescr && arrayDescr?.length > 0 ? arrayDescr : '',

    tosUpdatedAt: website?.tosUpdatedAt ? new Date(website?.tosUpdatedAt) : new Date(),
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {tos: string; tosUpdatedAt: Date}) => {
    setLoading(true)

    try {
      let translations = {
        ...website?.translations,
        [currLocale]: {
          tos: values.tos,
        },
      }
      const dataToSend: Partial<IEditWebsite> = {
        translations,
        tosUpdatedAt: dateState.toLocaleDateString(),
      }

      // if (typeof dateState === 'string') {
      //   dataToSend.tosUpdatedAt = new Date(dateState)
      // } else {
      dataToSend.tosUpdatedAt = dateState.toLocaleDateString()
      // }

      await websitesApi.editWebsite(website?.id, dataToSend)

      invalidateWebsite()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header d-flex justify-content-between align-items-center py-5'>
        <h3>Текст угоди</h3>{' '}
        <AgreementDate dateState={dateState || website?.tosUpdatedAt} setDateState={setDateState} />
      </div>

      {website ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-tos-text'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {values?.tos && (
                  <CKEInput inputName={`tos`} value={values.tos} extended={true} key={currLocale} />
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
