import {Formik, Form} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IEditStore} from '../../core/_models'
import {editStore} from '../../core/_requests'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../websites/core/_models'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'
import {CKEInput} from "../../../../modules/custom/form-elements/CKEInput";

interface Props {
  store: IEditStore
  invalidateStore: () => void
}

export function StoreText({store, invalidateStore}: Props) {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === store.website.id)

  const {locale} = useParams()
  const currLocale = locale || website?.mainLocale || 'uk'

  const arrayDescr =
    store?.translations &&
    store?.translations[currLocale] &&
    store?.translations[currLocale].description
  const initialValues: {description: string} = {
    // @ts-ignore
    description: arrayDescr?.length > 0 ? arrayDescr : '',
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {description: string}) => {
    setLoading(true)

    try {
      let translations = {
        ...store.translations,
        [currLocale]: {...initialValues, description: values.description},
        [currLocale]: {
          description: values.description,
        },
      }
      const dataToSend = {
        translations,
      }

      await editStore(dataToSend, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <h3>Текст статті</h3>
      </div>
      {store?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-text-store'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {store?.translations && (
                  <CKEInput value={values.description} inputName={`description`} key={currLocale} />
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
