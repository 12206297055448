import {useState} from 'react'
import {Formik, Form, ErrorMessage} from 'formik'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {FormattedMessage, useIntl} from 'react-intl'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {ICompanyDetails, ICompanyForm} from '../../../core/_models'
import {updateCompany} from '../../../core/_requests'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {WebsiteLanguage} from "../../../../websites/elements/WebsiteLanguage";
import {useQueries, useQuery} from "react-query";
import {QUERIES} from "../../../../../../_metronic/helpers";
import {websitesApi} from "../../../../websites/core/_requests";
import {directionsApi} from "../../../../directions/core/_requests";
import {getUserById} from "../../../../users/core/_requests";

interface Props {
  company: ICompanyDetails
  invalidateCompany: () => void
}

export function CompanyLanguages({company, invalidateCompany}: Props) {
  useQuery(QUERIES.LOCALES_LIST, websitesApi.fetchWebsiteLanguagesList)
  const initialValues: Pick<ICompanyDetails, 'mainLocale' | 'enabledLocales'> = {
    mainLocale: company.mainLocale || 'uk',
    enabledLocales: company.enabledLocales || ['uk'],
  }

  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<ICompanyForm>) => {
    try {
      setLoading(true)
      const response = await updateCompany({
        mainLocale: values.mainLocale,
        enabledLocales: values.enabledLocales,
      })
      if (response) {
        invalidateCompany()
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {company ? (
        <div className='card mb-5 mb-xl-10'>
          <Formik
            // validationSchema={profileOverviewSchema(intl)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue}) => (
              <Form noValidate className='form'>
                {/* {JSON.stringify(values)} */}

                <div className='card-body border-top p-9'>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                      <span>Оберіть активні мови сайту </span>
                    </label>
                    {company?.availableLocales.map((locale, index) => (
                      <WebsiteLanguage language={locale} key={index}/>
                    ))}

                    <div className='text-danger'>
                      <ErrorMessage name='enabledLocales'/>
                    </div>
                  </div>
                  <div className='fv-row'>
                    {values.enabledLocales && values.enabledLocales.length > 0 && (
                      <>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                          <span>Оберіть мову за замовчуванням</span>
                        </label>
                        {values.enabledLocales.map((locale, index) => (
                          <WebsiteLanguage
                            language={locale}
                            defaultLang={true}
                            key={index}
                          />
                        ))}

                        <div className='text-danger'>
                          <ErrorMessage name='mainLocale'/>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <CardFooter loading={loading} success={success}/>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <PartialPageLoader/>
      )}
    </>
  )
}
