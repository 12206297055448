import {Route, Routes} from 'react-router-dom'
import ChatTranslationsPage from './chat/ChatTranslationsPage'
import ClientTranslationsPage from './client/ClientTranslationsPage'
import TelegramTranslationsPage from './telegram/TelegramTranslationsPage'
import {useQuery} from "react-query";
import {getTranslationById} from "./client/core/_requests";
import {QUERIES} from "../../../_metronic/helpers";
import {getCompanyDetails} from "../settings/core/_requests";

const TranslationsPage = () => {
  useQuery(QUERIES.COMPANY_DETAILS, () => getCompanyDetails())
  return (
    <Routes>
      <Route path='chat/*' element={<ChatTranslationsPage />} />
      <Route path='client/*' element={<ClientTranslationsPage />} />
      <Route path='telegram/*' element={<TelegramTranslationsPage />} />

      {/* <Route path='company' element={< />} /> */}
    </Routes>
  )
}

export default TranslationsPage
