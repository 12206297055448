import {QUERIES} from '../../../../_metronic/helpers'
import {Field, useFormikContext} from 'formik'
import {ICreateNewWebsite} from '../core/_models'
import {useQueryClient} from 'react-query'

interface Props {
  language: string
  defaultLang?: boolean
  disableAll?: boolean
  disableChosen?: boolean
}

function WebsiteLanguage({
  language,
  defaultLang = false,
  disableAll = false,
  disableChosen = false,
}: Props) {
  const queryClient = useQueryClient()

  const locales: Record<string, string> = queryClient.getQueryData(QUERIES.LOCALES_LIST) || {}
  const {values, initialValues, setFieldValue} = useFormikContext<Pick<ICreateNewWebsite, 'mainLocale' | 'enabledLocales'>>()
  return (
    <label className='d-flex flex-stack cursor-pointer mb-5'>
      <span className='d-flex align-items-center me-2'>
        <span className='d-flex '>
          <span className='fw-bolder fs-6 text-capitalize'>{locales[language]}</span>
        </span>
      </span>

      <span className='form-check form-check-custom form-check-solid'>
        {defaultLang === true ? (
          <Field
            className='form-check-input'
            type='radio'
            name='mainLocale'
            value={language}
            disabled={disableAll}
            checked={
              values.enabledLocales.length === 1
                ? values.enabledLocales[0]
                : values.mainLocale === language
            }
          />
        ) : (
          <Field
            className='form-check-input'
            type='checkbox'
            name='locales'
            value={language}
            disabled={disableChosen ? initialValues.enabledLocales.includes(language) : false}
            onChange={(e: any) => {
              setFieldValue(
                'enabledLocales',
                !values.enabledLocales.includes(language)
                  ? [...values.enabledLocales, e.target.value]
                  : values.enabledLocales.filter((i) => i !== e.target.value)
              )
              if (values.enabledLocales.length === 1)
                setFieldValue('mainLocale', values.enabledLocales[0])
            }}
            checked={values.enabledLocales.includes(language)}
          />
        )}
      </span>
    </label>
  )
}

export {WebsiteLanguage}
