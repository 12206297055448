import {useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {IWebsite} from '../../../../websites/core/_models'
import {ITelegramTranslation} from '../../core/_models'
import {useQueryClient} from "react-query";
import {ICompanyDetails} from "../../../../settings/core/_models";
import {QUERIES} from "../../../../../../_metronic/helpers";

interface Props {
  translation: ITelegramTranslation
  website: IWebsite | undefined
}

const TranslationHeaderLangSwitcher = ({translation, website}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const queryClient = useQueryClient()
  const companyData: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
  const enabledLocales = companyData ? companyData.enabledLocales : []
  const defaultLocale = companyData ? companyData.mainLocale : 'uk'

  if (enabledLocales.includes(defaultLocale)) {
    const defaultIndex = enabledLocales.indexOf(defaultLocale)
    enabledLocales.splice(defaultIndex, 1)
    enabledLocales.unshift(defaultLocale)
  }

  return (
    <ul className='nav flex-nowrap'>
      {translation &&
        enabledLocales.map((locale) => (
          <li className='nav-item' key={locale}>
            <Link
              to={`/translations/telegram/edit/${currTab}/${locale}/${translation.keyName}`}
              onClick={() => {
                setActiveTab(locale)
              }}
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                activeTab === locale ? 'active' : ''
              } fw-bold px-4`}
            >
              {locale}
            </Link>
          </li>
        ))}
    </ul>
  )
}

export default TranslationHeaderLangSwitcher
