import {websitesApi} from '../core/_requests'
import {ErrorMessage} from 'formik'
import {LogoThumb} from './LogoThumb'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  title: string
  inputName: string
  setFieldValue: ISetFieldValue
  logoUrl?: string | null
}

function WebsiteLogoItem({
  title,
  inputName,
  setFieldValue,
  logoUrl,
}: Props) {
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    const response = await websitesApi.uploadImages(inputName, fileObj)
    setFieldValue(inputName, response[Object.keys(response)[0]])
  }
  const editingLogo = ';';
  return (
    <li>
      {' '}
      <label className='d-flex flex-stack cursor-pointer mb-5'>
        <span className='d-flex align-items-center me-2'>
          <span className='d-flex flex-column'>
            <span className='fw-bolder fs-6'>{title}</span>
          </span>
        </span>

        <span className='form-check form-check-custom form-check-solid'>
          <input
            id={inputName}
            name={inputName}
            className='form-control form-control-lg form-control-solid'
            type='file'
            onChange={handleImageUpload}
          />
        </span>

        {logoUrl && (
          <LogoThumb link={logoUrl} alt={title} />
        )}
      </label>
      <div className='text-danger'>
        <ErrorMessage name={inputName} />
      </div>
    </li>
  )
}

export {WebsiteLogoItem}
