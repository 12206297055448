import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import {ClientTranslationsListWrapper} from './translations-list/ClientTranslationsList'
import {PageViewProvider} from './core/PageViewProvider'
import ClientTranslationPage from './translation-page/ClientTranslationPage'
import {useQuery} from "react-query";
import {QUERIES} from "../../../../_metronic/helpers";
import {getCompanyDetails} from "../../settings/core/_requests";

const ClientTranslationsPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<ClientTranslationsListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <ClientTranslationPage
                id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/translations/client/list`} />} />
    </Routes>
  )
}

export default ClientTranslationsPage
