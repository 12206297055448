import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ICompanyDetails} from '../../core/_models'

export function SettingsHeaderNav() {
  const location = useLocation()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/settings/edit/overview` && 'active')
            }
            to={`/settings/edit/overview`}
          >
            Основні{' '}
          </Link>
        </li>{' '}
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/settings/edit/languages` && 'active')
            }
            to={`/settings/edit/languages`}
          >
            Мови{' '}
          </Link>
        </li>{' '}
      </ul>
    </div>
  )
}
